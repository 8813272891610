import React from "react";

export const Features = () => {
  const features = [
    {
      icon: "fa fa-child",
      title: "Directed by a Certified Teacher",
      text: `With over three decades of experience in early childhood education, along with early childhood and teaching
        certifications, our owner brings a holistic understanding of  young children. This experience and expertise ensures
        that Kids Cottage provides every child with the best education and care, shaping a brighter
        future for all of our children, providing them with a solid foundation for success.`,
    },
    {
      icon: "fa fa-group",
      title: "Small Class Sizes",
      text: `The magic at Kids Cottage lies in its commitment to keeping class sizes small. We understand
        that each child is an individual on a unique learning journey. Small classes mean more one-on-one attention, so that
        teachers can learn about children's strengths, interests, and challenges. Small class sizes are the key to unlocking the full
        potential of every young mind.`,
    },
    {
      icon: "fa fa-comments-o",
      title: "Parent and Family Education",
      text: `We are excited to partner with our families by sharing fun activities, information, ideas, and advice on
      an array of parenting and early childhood topics. We are always available for a chat or personal advice on that
      might assist your child or family. Together we can help your child grow and develop to their maximum potential!`,
    },
  ];

  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-12 section-title mb-none">
          <h2 className="playpens-font">Why Choose Kids Cottage?</h2>
          <p>
            <strong>
              Kids Cottage offers many features that you are not likely to find
              at other learning and care centers.
            </strong>
          </p>
        </div>
        <div className="row">
          {features.map((d, i) => (
            <div
              key={`${d.title}-${i}`}
              className="col-xs-12 col-md-4 section-item"
            >
              <i className={d.icon}></i>
              <h3
                className="playpens-font bold caps"
                style={{ fontSize: "19px" }}
              >
                {d.title}
              </h3>
              <p className="justify-text mt-20">{d.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
