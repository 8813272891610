import React from "react";

export const Navigation = () => {
  const topNavLinks = [
    {
      displayName: "About",
      isHidden: true,
    },
    {
      displayName: "Gallery",
      link: "https://www.facebook.com/media/set/?set=a.221128451055471&type=3",
      isExternal: true,
    },
    {
      displayName: "Staff",
      link: "https://www.facebook.com/media/set/?set=a.259324797235836&type=3",
      isExternal: true,
    },
    {
      displayName: "Parent Connection",
      link: "https://www.facebook.com/media/set/?set=a.221129624388687&type=3",
      isExternal: true,
    },
    {
      displayName: "Enrollment",
      link: "https://forms.gle/pMeZbcxBWcvCYfsJ6",
      isExternal: true,
    },
    {
      displayName: "Learning",
      link: "/learning",
      isHidden: true,
    },
    {
      displayName: "Parent Corner",
      isHidden: true,
    },
    {
      displayName: "Testimonials",
      isHidden: true,
    },
    {
      displayName: "Employment",
      link: "https://forms.gle/nbMDDN6PiRnH67CPA",
      isExternal: true,
    },
    {
      displayName: "Contact",
      link: "#contact",
      isShown: true,
    },
  ];

  const renderTopNavLinks = () => {
    return topNavLinks
      .filter((topNavLink) => !topNavLink.isHidden)
      .map((topNavLink) => {
        const { displayName, link, isExternal } = topNavLink;

        return (
          <li>
            <a
              href={link}
              target={isExternal ? "_blank" : "_self"}
              rel="noreferrer"
              className="playpens-font"
            >
              {displayName}
            </a>
          </li>
        );
      });
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a
            href="/"
            className="navbar-brand page-scroll kids-cottage-title playpens-font"
          >
            Kids Cottage
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">{renderTopNavLinks()}</ul>
        </div>
      </div>
    </nav>
  );
};
