import React from "react";

export const Enrollment = () => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-12 intro-text text-body">
                <div className="main-glass-effect glass-effect">
                  <h1 className="playpens-font">
                    Learning Through Discovery And Play
                  </h1>
                  <p className="justify-text mt-40">
                    At Kids Cottage, we specialize in meeting each child’s
                    unique learning needs, in a small family-like setting. With
                    our dedicated staff, we provide individualized attention,
                    creating a nurturing space where kids not only learn
                    academic skills but also build social connections. It's more
                    than childcare; it's a place where every child's journey is
                    recognized and celebrated.
                  </p>
                  <a
                    href="https://forms.gle/pMeZbcxBWcvCYfsJ6"
                    className="btn btn-custom btn-lg page-scroll"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Enroll Today!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
