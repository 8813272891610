import React from "react";

export const Contact = () => {
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-12">
            <div className="section-title">
              <h2 className="playpens-font">Get In Touch</h2>
            </div>
          </div>
          <div className="col-md-7">
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> <strong>Address</strong>
                </span>
                <a
                  className="white-text link-white-text-hover"
                  href="https://www.google.com/maps/place/16121+Eureka+Road+Southgate,+MI,+US+48195/@42.1986305,-83.2199503,3a,75y,126.7h,90t/data=!3m4!1e1!3m2!1sAPcIM7ih_XdeRMbYhDxW8g!2e0!4m2!3m1!1s0x883b3788b386550f:0xe484b7c1555d93dc?sa=X&ved=2ahUKEwiA_eOyitOBAxV7kYkEHTaeAaYQxB16BAgXEAA"
                  target="_blank"
                  rel="noreferrer"
                >
                  16121 Eureka Road Southgate, MI, US 48195
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> <strong>Phone</strong>
                </span>
                <a
                  className="white-text link-white-text-hover"
                  href="tel:734-440-9694"
                >
                  (734) 440-9694
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-5">
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> <strong>Email</strong>
                </span>
                <a
                  className="white-text link-white-text-hover"
                  href="mailto:KidsCottageEarlyLearning@gmail.com"
                >
                  KidsCottageEarlyLearning@gmail.com
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-facebook"></i> <strong>Facebook</strong>
                </span>
                <a
                  className="white-text link-white-text-hover"
                  href="https://www.facebook.com/profile.php?id=100094749512397"
                  target="_blank"
                  rel="noreferrer"
                >
                  Kids Cottage Early Learning Center Facebook Page
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row text-center social">
              <p>
                Website by{" "}
                <a
                  href="http://www.wileybui.com"
                  target="_blank"
                  rel="nofollow noreferrer"
                  className="white-text link-white-text-hover"
                >
                  Wiley Bui
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
