import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Navigation } from "./components/Main/Navigation";
import { Contact } from "./components/Main/Contact";
import SmoothScroll from "smooth-scroll";
import LandingPage from "./pages/LandingPage";
import LearningPage from "./pages/LearningPage";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
    <BrowserRouter>
      <div>
        <Navigation />
        <div style={{ paddingTop: "70px" }}></div>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="learning" element={<LearningPage />} />
          <Route path="*" element={<LandingPage />} />
        </Routes>
        <Contact />
      </div>
    </BrowserRouter>
  );
};

export default App;
