import React from "react";
import SmoothScroll from "smooth-scroll";
import "./LearningPage.scss";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const LearningPage = () => {
  return (
    <div id="learning" className="container">
      <div className="text-center mt-60">
        {/* <div className="col-md-12 section-title"> */}
        <h2>How We Learn</h2>
        <p className="justify-text mt-30">
          At Kids Cottage, we embrace each child’s individuality, fostering an
          environment where learning happens at their unique developmental pace.
          Experiences are carefully curated to facilitate learning throughout
          our day including large and small group activities as well as during
          free play, outside time and mealtimes. At Kids Cottage every aspect of
          a child's growth is nurtured with care and expertise!
        </p>
        {/* </div> */}
        {/* <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div> */}
      </div>
      <div id="learning-environment" className="container">
        <div className="text-center">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>Learning Environment</h2>
            <p className="justify-text mt-30">
              Our <strong>preschool classroom</strong> is divided into centers,
              focusing on: writing, reading, science and math, building, art and
              dramatic play. Children learn many skills and concepts in each
              area as they play and interact with materials, other children and
              teachers.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearningPage;
