import React from "react";
import { Enrollment } from "../components/Landing/Enrollment";
import { Features } from "../components/Landing/Features";
import SmoothScroll from "smooth-scroll";
import "./LandingPage.scss";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const LandingPage = () => {
  return (
    <div>
      <Enrollment />
      <Features />

      {/*
        <About data={landingPageData.About} />
        <Services data={landingPageData.Services} />
        <Gallery data={landingPageData.Gallery} />
        <Testimonials data={landingPageData.Testimonials} />
        <Team data={landingPageData.Team} />
    */}
    </div>
  );
};

export default LandingPage;
